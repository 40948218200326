import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setAppBuilderReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : data
    });
}

export const setAppBuilderSync = (params={}) => dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_SYNC',
        data : params['data'],
        stateField : params['stateField'],
        objectField : params['objectField']
    });
}

export const setReducerAppData = (data={}, updateSettings=false) => dispatch => {
    if(updateSettings){
        dispatch({
            type : 'SET_REDUCER_APP_SETTINGS',
            payload : data
        });
    }else{
        dispatch({
            type : 'SET_REDUCER_APP_DATA',
            payload : data
        });
    }
}

export const setElementAppData = (params={}) => dispatch => {
    dispatch({
        type : 'SET_ELEMENT_APP_DATA',
        payload : {
            data : params['data'],
            elementId : params['elementId'],
            refresh : params['refresh'] ? true : false,
            clearCache : params['clearCache']
        }
    });
}

export const setAppbuilderDataInReducer = (payload={}) => dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_DATA_IN_REDUCER',
        payload
    });
}

export const setOnloadAnimationTracker = (elementId, value) => dispatch => {
    dispatch({
        type : 'SET_ONLOAD_ANIMATION_TRACKER',
        elementId,
        value
    })
}

export const replaceReducerAppData = (data={}) => dispatch => {
    dispatch({
        type : 'REPLACE_REDUCER_APP_DATA',
        payload : data
    });
}

export const resetAppBuilderReducer = (data={}) => dispatch => {
    dispatch({
        type : 'RESET_APP_BUILDER_REDUCER',
        payload : data
    });
}

export const getAppBuilderApp = (data={}) => async dispatch => {
    dispatch({
        type : 'APP_BUILDER_APP_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/getcompressedapp`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_APP_BUILDER_APP_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'APP_BUILDER_APP_ERROR',
            payload : request
        })
    }
}

export const getAppBuilderApps = (data={}) => async dispatch => {
    dispatch({
        type : 'APP_BUILDER_APP_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/getapps`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_APP_BUILDER_APPS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'APP_BUILDER_APP_ERROR',
            payload : request
        })
    }
}

export const getPartialAppBuilderApp = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            appBuilderLoading : true,
            appBuilderError : false,
            appBuilderErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/getcompressedapp`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        if(data['modalMode']){
            dispatch({
                type : 'SET_MODAL_MODE_APP_BUILDER_APP_SUCCESS',
                payload : request
            });
        }else{
            dispatch({
                type : 'SET_PARTIAL_APP_BUILDER_APP_SUCCESS',
                payload : request
            });
        }
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                appBuilderLoading : false,
                appBuilderError : true,
                appBuilderErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const mergeMultiplayerSyncElements = (data={}) => dispatch => {
    dispatch({
        type : 'MERGE_MULTIPLAYER_SYNC_ELEMENTS',
        payload : data
    });
}

export const editAppBuilderApp = (data={}) => async dispatch => {
    dispatch({
        type : 'APP_UPDATE_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/editapp`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'APP_UPDATE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'APP_UPDATE_ERROR',
            payload : request
        })
    }
}

export const logExternalUserEditorAccess = (data={}) => async dispatch => {
    dispatch({
        type : 'LOG_EXTERNAL_USER_EDITOR_ACCESS_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/logexternalusereditoraccess`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'LOG_EXTERNAL_USER_EDITOR_ACCESS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'LOG_EXTERNAL_USER_EDITOR_ACCESS_ERROR',
            payload : request
        })
    }
}

export const setAppBuilderAppPassword = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            passwordSetLoading : true,
            passwordSetError : false,
            passwordSetErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/appaccess/${data['changeRequestPassword'] ? 'changepassword' : 'setpassword'}`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                passwordSetLoading : false,
                passwordSetError : false,
                passwordSetErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                passwordSetLoading : false,
                passwordSetError : true,
                passwordSetErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const requestPasswordChange = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            passwordSetLoading : true,
            passwordSetError : false,
            passwordSetErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/appaccess/requestpasswordchange`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                passwordSetLoading : false,
                passwordSetError : false,
                passwordSetErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                passwordSetLoading : false,
                passwordSetError : true,
                passwordSetErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getSavedAppElements = (data={}, append=false) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            getSavedAppElementsLoading : true,
            getSavedAppElementsError : false,
            getSavedAppElementsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/elements/getsavedappelements`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_SAVED_APP_ELEMENTS_SUCCESS',
            payload : request,
            append
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                getSavedAppElementsLoading : false,
                getSavedAppElementsError : true,
                getSavedAppElementsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteSavedAppElement = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            deleteSavedAppElementLoading : true,
            deleteSavedAppElementError : false,
            deleteSavedAppElementErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/elements/delete`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                deleteSavedAppElementLoading : false,
                deleteSavedAppElementError : false,
                deleteSavedAppElementErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                deleteSavedAppElementLoading : false,
                deleteSavedAppElementError : true,
                deleteSavedAppElementErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const saveAppElement = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            saveAppElementLoading : true,
            saveAppElementError : false,
            saveAppElementErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/elements/save`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                saveAppElementLoading : false,
                saveAppElementError : false,
                saveAppElementErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                saveAppElementLoading : false,
                saveAppElementError : true,
                saveAppElementErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const launchApp = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            launchRequestLoading : true,
            launchRequestError : false,
            launchRequestErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/viewbuilder/build`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                launchRequestLoading : false,
                launchRequestError : false,
                launchRequestErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                launchRequestLoading : false,
                launchRequestError : true,
                launchRequestErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const commitApp = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            appCommitLoading : true,
            appCommitError : false,
            appCommitErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/commitapp`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                appCommitLoading : false,
                appCommitError : false,
                appCommitErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                appCommitLoading : false,
                appCommitError : true,
                appCommitErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getAppCommits = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            getAppCommitsLoading : true,
            getAppCommitsError : false,
            getAppCommitsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/getappcommits`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_COMMITS',
            payload : {
                getAppCommitsLoading : false,
                getAppCommitsError : false,
                getAppCommitsErrorMessage : '',
                appCommits : request['results'] || [],
                appCommitsTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                getAppCommitsLoading : false,
                getAppCommitsError : true,
                getAppCommitsErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const setCommitVersion = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            setCommitVersionLoading : true,
            setCommitVersionError : false,
            setCommitVersionErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/setcommitversion`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                setCommitVersionLoading : false,
                setCommitVersionError : false,
                setCommitVersionErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                setCommitVersionLoading : false,
                setCommitVersionError : true,
                setCommitVersionErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getAppRebaseFallbacks = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            getAppRebaseFallbacksLoading : true,
            getAppRebaseFallbacksError : false,
            getAppRebaseFallbacksErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/getrebasefallbacks`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                getAppRebaseFallbacksLoading : false,
                getAppRebaseFallbacksError : false,
                getAppRebaseFallbacksErrorMessage : '',
                appRebaseFallbacks : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                getAppRebaseFallbacksLoading : false,
                getAppRebaseFallbacksError : true,
                getAppRebaseFallbacksErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const setAppRebaseFallback = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            setRebaseFallbackLoading : true,
            setRebaseFallbackError : false,
            setRebaseFallbackErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/setrebasefallback`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                setRebaseFallbackLoading : false,
                setRebaseFallbackError : false,
                setRebaseFallbackErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                setRebaseFallbackLoading : false,
                setRebaseFallbackError : true,
                setRebaseFallbackErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getQueuedDeployment = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            queuedDeploymentLoading : true,
            queuedDeploymentError : false,
            queuedDeploymentErrorMessage : '',
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/viewbuilder/getqueueddeployment`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                queuedDeploymentLoading : false,
                queuedDeploymentError : false,
                queuedDeploymentErrorMessage : '',
                queuedDeployments : request.results || [],
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                queuedDeploymentLoading : false,
                queuedDeploymentError : true,
                launchRequestErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const sendBuildReview = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            buildApprovalLoading : true,
            buildApprovalError : false,
            buildApprovalErrorMessage : '',
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/viewbuilder/buildapproval`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                buildApprovalLoading : false,
                buildApprovalError : false,
                buildApprovalErrorMessage : '',
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                buildApprovalLoading : false,
                buildApprovalError : true,
                buildApprovalErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getBuildReports = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            getDeploymentReportsLoading : true,
            getDeploymentReportsError : false,
            getDeploymentReportsErrorMessage : '',
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/viewbuilder/getbuildreports`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                getDeploymentReportsLoading : false,
                getDeploymentReportsError : false,
                getDeploymentReportsErrorMessage : '',
                deploymentReports : request.results || [],
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                getDeploymentReportsLoading : false,
                getDeploymentReportsError : true,
                getDeploymentReportsErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const mergeApp = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            deploymentMergeLoading : true,
            deploymentMergeError : false,
            deploymentMergeErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/viewbuilder/mergebuild`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                deploymentMergeLoading : false,
                deploymentMergeError : false,
                deploymentMergeErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                deploymentMergeLoading : false,
                deploymentMergeError : true,
                deploymentMergeErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const deleteInhouzApp = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            deleteAppLoading : true,
            deleteAppError : false,
            deleteAppErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/deleteapp`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                deleteAppLoading : false,
                deleteAppError : false,
                deleteAppErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                deleteAppLoading : false,
                deleteAppError : true,
                deleteAppErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const activateCustomDomain = (data={}, i) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            customDomainActivationLoading : true,
            customDomainActivationError : false,
            customDomainActivationErrorMessage : '',
            customDomainIndex : i
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/activatecustomdomain`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                customDomainActivationLoading : false,
                customDomainActivationError : false,
                customDomainActivationErrorMessage : '',
                customDomainIndex : i,
                newCustomDomainId : request['cloudflareId'] || ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                customDomainActivationLoading : false,
                customDomainActivationError : true,
                customDomainActivationErrorMessage : request.errorMessage || '',
                customDomainIndex : i
            }
        });
    }
}

export const deactivateCustomDomain = (data={}, i) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            customDomainDeactivationLoading : true,
            customDomainDeactivationError : false,
            customDomainDeactivationErrorMessage : '',
            customDomainIndex : i
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/deactivatecustomdomain`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                customDomainDeactivationLoading : false,
                customDomainDeactivationError : false,
                customDomainDeactivationErrorMessage : '',
                customDomainIndex : i
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                customDomainDeactivationLoading : false,
                customDomainDeactivationError : true,
                customDomainDeactivationErrorMessage : request.errorMessage || '',
                customDomainIndex : i
            }
        });
    }
}

export const setBuilderWebComponent = (params={}) => async dispatch => {
    const {
        app={}, appId=''
    } = params;
    dispatch({
        type : 'SET_BUILDER_WEB_COMPONENT',
        payload : {
            appId,
            app
        }
    });
}

export const updateGenericElementCacheInAppBuilderReducer = (params={}) => dispatch => {
    dispatch({
        type : 'UPDATE_GENERIC_ELEMENT_CACHE_IN_APP_BUILDER_REDUCER',
        payload : {
            data : params['data'],
            elementId : params['elementId'],
            field : params['field']
        }
    });
}

export const undeployInhouzApp = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            undeployAppLoading : true,
            undeployAppError : false,
            undeployAppErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/undeploy`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                undeployAppLoading : false,
                undeployAppError : false,
                undeployAppErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                undeployAppLoading : false,
                undeployAppError : true,
                undeployAppErrorMessage : request.errorMessage || ''
            }
        });
    }
}