import config from "../../config/config";
import dotNotationObjectUpdate from "../../utils/data/dotNotationObjectUpdate";
// import sampleAppBuild from "./sampleAppBuild";
const initialState = {
    // encryptedBuild : sampleAppBuild.buildData,
    appMap : {},
    user : {},
    userAgent : {},
    appDeviceMode : 'desktop',
    sliderPositionFieldMap : {},
    tabPositionFieldMap : {},
    tableColumnVisibilityMap : {},
    tableWidthMapCache : {},
    paymentElementPublicKeyCache : {},
    paymentElementPaymentIntentCache : {},
    listKeyTrackerMapCache : {},
    efficientFileManagerIdMap : {}
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_APP_VIEWER_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_APP_VIEWER_APP_OBJECT':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        ...action.payload.data
                    }
                }
            }
        case 'INITIALIZE_APP_VIEWER_APP':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : action.payload.appData
                }
            }
        case 'UPDATE_GENERIC_ELEMENT_CACHE_IN_REDUCER':
            return {
                ...state,
                [action.payload.field] : {
                    ...state[action.payload.field],
                    [action.payload.elementId] : {
                        ...state[action.payload.field][action.payload.elementId],
                        ...action.payload.data
                    }
                }
            }
        case 'SET_VIEWER_APP_DATA':
            let placeholderObj = {};
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        App_Data : state.appMap[action.payload.appId] ? 
                        {
                            ...state.appMap[action.payload.appId]['App_Data'],
                            ...action.payload.data,
                            appSettings : state.appMap[action.payload.appId]['App_Data']['appSettings']
                        }
                        :
                        placeholderObj
                    }
                }
            }
        case 'SET_APP_VIEWER_DATA_IN_REDUCER':
            let path = action.payload.path || '';
            let updateData = action.payload.data || '';
            let finalUpdate = {};
            if(path){
                finalUpdate = dotNotationObjectUpdate(
                    state.appMap[action.payload.appId]['App_Data'],
                    updateData,
                    path
                );
            }else{
                finalUpdate = {
                    ...state.appMap[action.payload.appId]['App_Data'],
                    ...updateData
                }
            }

            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        App_Data : {
                            ...finalUpdate,
                            appSettings : state.appMap[action.payload.appId]['App_Data']['appSettings']
                        }
                    }
                }
            }
        case 'SET_VIEWER_APP_SETTINGS':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        App_Data : {
                            ...state.appMap[action.payload.appId]['App_Data'],
                            appSettings : {
                                ...state.appMap[action.payload.appId]['App_Data']['appSettings'],
                                ...action.payload.data
                            }
                        }
                    }
                }
            }
        case 'SET_VIEWER_ELEMENT_APP_DATA':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        elementAppData : {
                            ...state.appMap[action.payload.appId].elementAppData,
                            [action.payload.elementId] : state.appMap[action.payload.appId].elementAppData[action.payload.elementId] ? 
                            {
                                ...state.appMap[action.payload.appId].elementAppData[action.payload.elementId],
                                ...action.payload.data
                            }
                            :
                            action.payload.data
                        },
                        elementAppDataTracker : {
                            ...state.appMap[action.payload.appId].elementAppDataTracker,
                            [action.payload.elementId] : action.payload.refresh ? 
                            Math.random() : state.appMap[action.payload.appId].elementAppDataTracker[action.payload.elementId]
                        },
                        clearElementAppDataTracker : {
                            ...state.appMap[action.payload.appId].clearElementAppDataTracker,
                            [action.payload.elementId] : action.payload.clearCache
                        }
                    }
                }
            }
        case 'SET_VIEWER_WEB_COMPONENT':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appInFocusId] : {
                        ...state.appMap[action.payload.appInFocusId],
                        webComponentCache : {
                            ...state.appMap[action.payload.appInFocusId]['webComponentCache'],
                            [action.payload.appId] : action.payload.app
                        }
                    }
                }
            }
        case 'UPDATE_EFFICIENT_FILE_MANAGER_MAP':
            return {
                ...state,
                efficientFileManagerIdMap : {
                    ...state.efficientFileManagerIdMap,
                    [action.payload.appId] : [
                        ...state['efficientFileManagerIdMap'][action.payload.appId],
                        action.payload.data
                    ]
                }
            }
        default:
            return state;
    }
}