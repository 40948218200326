const initialState = {
    isOnline : true
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SET_SYSTEM_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}